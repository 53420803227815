import axios from "axios";
const getAccessToken = () => localStorage.getItem("accessToken");

const token = getAccessToken();
export const headers = {
  "Content-Type": "application/json",
};
const getAxiosInterceptor = () => {
  const axiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  // Add a request interceptor
  axiosInterceptor.interceptors.request.use(
    async (config) => {
      // Retrieve the access token here, instead of globally
      const token = localStorage.getItem("accessToken");

      // Add Authorization header only if token exists
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosInterceptor.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInterceptor;
};

export default getAxiosInterceptor;
