import React from 'react'

const Error = ({error}: {error: string}) => {
  return (
    <div>
        {error ? (
        <p className="text-sm text-red-500">{error}</p>
        ) : null}
    </div>
  )
}

export default Error