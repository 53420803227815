import React, { useEffect, useRef, useState } from "react";

import GoogleMapReact from "google-map-react";

import {
  handledataSubmition,
  mapSearch,
  resultMapSearch,
} from "../services/digital-address/api";

import useDebounce from "../utils/hooks";
import { geocode, RequestType } from "react-geocode";

import DefaultIcon from "../assets/images/DefaultIcon.png";

import SatelliteIcon from "../assets/images/SatelliteIcon.png";
import Popup from "../components/api-completed";
import { useLocation, useNavigate } from "react-router-dom";

const AnyReactComponent = ({ text }: any) => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="black"
      viewBox="0 0 24 24"
      className="w-12 h-12"
    >
      <g stroke-linecap="square" stroke-linejoin="round" stroke-width="0.8">
        <g>
          <g>
            <path
              stroke-width="0"
              d="M2.08,7.02V2.67c0-.33.27-.6.6-.6h4.35v1.2h-3.75v3.75h-1.2Z"
            ></path>
            <path
              fill="#ffffff"
              d="M6.83,2.27v.8h-3.75v3.75h-.8V2.67c0-.22.18-.4.4-.4h4.15M7.23,1.87H2.68c-.44,0-.8.36-.8.8v4.55h1.6v-3.75h3.75v-1.6h0Z"
            ></path>
          </g>
          <g>
            <path
              stroke-width="0"
              d="M18.58,7.02v-3.75h-3.75v-1.2h4.35c.33,0,.6.27.6.6v4.35h-1.2Z"
            ></path>
            <path
              fill="#ffffff"
              d="M19.18,2.27c.22,0,.4.18.4.4v4.15h-.8v-3.75h-3.75v-.8h4.15M19.18,1.87h-4.55v1.6h3.75v3.75h1.6V2.67c0-.44-.36-.8-.8-.8h0Z"
            ></path>
          </g>
          <g>
            <path
              stroke-width="0"
              d="M14.83,19.77v-1.2h3.75v-3.75h1.2v4.35c0,.33-.27.6-.6.6h-4.35Z"
            ></path>
            <path
              fill="#ffffff"
              d="M19.58,15.02v4.15c0,.22-.18.4-.4.4h-4.15v-.8h3.75v-3.75h.8M19.98,14.62h-1.6v3.75h-3.75v1.6h4.55c.44,0,.8-.36.8-.8v-4.55h0Z"
            ></path>
          </g>
          <g>
            <path
              stroke-width="0"
              d="M2.68,19.77c-.33,0-.6-.27-.6-.6v-4.35h1.2v3.75h3.75v1.2H2.68Z"
            ></path>
            <path
              fill="#ffffff"
              d="M3.08,15.02v3.75h3.75v.8H2.68c-.22,0-.4-.18-.4-.4v-4.15h.8M3.48,14.62h-1.6v4.55c0,.44.36.8.8.8h4.55v-1.6h-3.75v-3.75h0Z"
            ></path>
          </g>
          <circle
            fill="#db0000"
            stroke="#ffffff"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-width="0.8"
            cx="10.93"
            cy="10.92"
            r="1.95"
          ></circle>
          <g>
            <rect
              stroke-width="0"
              x="10.33"
              y=".2"
              width="1.2"
              height="4.95"
            ></rect>
            <path
              fill="#ffffff"
              d="M11.33.4v4.55h-.8V.4h.8M11.73,0h-1.6v5.35h1.6V0h0Z"
            ></path>
          </g>
          <g>
            <rect
              stroke-width="0"
              x="10.33"
              y="16.7"
              width="1.2"
              height="4.95"
            ></rect>
            <path
              fill="#ffffff"
              d="M11.33,16.9v4.55h-.8v-4.55h.8M11.73,16.5h-1.6v5.35h1.6v-5.35h0Z"
            ></path>
          </g>
          <g>
            <rect
              stroke-width="0"
              x="16.7"
              y="10.32"
              width="4.95"
              height="1.2"
            ></rect>
            <path
              fill="#ffffff"
              d="M21.45,10.52v.8h-4.55v-.8h4.55M21.85,10.12h-5.35v1.6h5.35v-1.6h0Z"
            ></path>
          </g>
          <g>
            <rect
              stroke-width="0"
              x=".2"
              y="10.33"
              width="4.95"
              height="1.2"
            ></rect>
            <path
              fill="#ffffff"
              d="M4.95,10.53v.8H.4v-.8h4.55M5.35,10.13H0v1.6h5.35v-1.6h0Z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
);
const rerender = () => {
  return AddDigitalAddressStep;
};
const AddDigitalAddressStep = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState(Date.now());
  const [marketLocation, setMarketLocation] = useState("");
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [placeIdentifier, setPlaceIdentifier] = useState("");
  const [maps, setMaps] = useState<typeof google.maps | null>(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setLoading] = useState(false);
  const [isMapLocked, setIsMapLocked] = useState(false);
  const [mapType, setMapType] = useState<"roadmap" | "satellite">("satellite");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [isOpen, setIsOpen] = useState(false);
  const [digitaladress, setDigitaladress] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const geocoderRef = useRef<google.maps.Geocoder | null>(null);
  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const defaultProps = {
    zoom: 11,
  };
  useEffect(() => {
    setKey(Date.now()); // Update the key when the component mounts
  }, []);
  const [center, setCenter] = useState({
    lat: 33.2587427436258,
    lng: -8.509214087001311,
  });
  const [marker, setMarker] = useState({ ...center, text: "Initial Marker" });
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (debouncedSearchQuery === "") return;

    mapSearch(debouncedSearchQuery)
      .then((res) => {
        setSearchResults(res.data.predictions);
      })

      .catch((er) => console.error(er));
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (placeIdentifier) {
      resultMapSearch(placeIdentifier)
        .then((res: any) => {
          setCenter({
            lat: res.data.result.geometry.location.lat,
            lng: res.data.result.geometry.location.lng,
          });

          const viewport = res.data.result.geometry.viewport;
          const newBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(
              viewport.southwest.lat,
              viewport.southwest.lng
            ),
            new google.maps.LatLng(
              viewport.northeast.lat,
              viewport.northeast.lng
            )
          );

          setBounds(newBounds);
        })
        .catch((error: any) => {
          console.error("Error fetching place details:", error);
        });
    }
  }, [placeIdentifier]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { top: 10, bottom: 10, left: 10, right: 10 });
      map.setOptions({
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: false,
      });
    }
  }, [bounds]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds);
    }
  }, [map, bounds]);

  const handleApiLoaded = ({ map, maps }: MapsApi) => {
    setMap(map);
    setMapType(google.maps.MapTypeId.ROADMAP);

    geocoderRef.current = new maps.Geocoder();

    map.setOptions({
      zoomControl: true,
      fullscreenControl: false,
      minZoom: 1,
    });

    if (geocoderRef.current) {
      geocoderRef.current.geocode(
        { address: "New York" },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            console.log(results); // Log or handle geocoding result
            console.log("API Loaded", map, maps);
          } else {
            console.error(
              "Geocode was not successful for the following reason:",
              status
            );
          }
        }
      );
    }

    if (!maps.Geocoder) {
      console.error("Geocoder API not loaded");
    }
  };

  const toggleMapType = () => {
    setMapType((currentType) =>
      currentType === google.maps.MapTypeId.ROADMAP
        ? google.maps.MapTypeId.SATELLITE
        : google.maps.MapTypeId.ROADMAP
    );
  };

  const displaytheNameOfthePlace = (lat: number, lng: number) => {
    geocode("latlng", lat + "," + lng, {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      language: "en",
      region: "us",
      outputFormat: OutputFormat.JSON,
    }).then((response) => {
      setMarketLocation(response.plus_code.compound_code);
    });
  };
  const geocodeLatLng = (
    geocoder: google.maps.Geocoder,
    lat: number,
    lng: number
  ): Promise<google.maps.GeocoderResult[]> => {
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(results);
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          reject(
            new Error(
              "Geocoding succeeded but returned no results. This location may not be recognized."
            )
          );
        } else {
          reject(new Error(`Geocoding failed: ${status}`));
        }
      });
    });
  };
  const handleMapChange = ({ center }: MapChangeEvent) => {
    if (geocoderRef.current) {
      // Check if the geocoder is initialized
      geocodeLatLng(geocoderRef.current, center.lat, center.lng);
    } else {
      console.error("Geocoder is not initialized");
    }
    setMarker({
      ...marker,
      lat: clampLatitude(center.lat),
      lng: clampLongitude(center.lng),
    });

    setCenter({
      lat: clampLatitude(center.lat),
      lng: clampLongitude(center.lng),
    });
    displaytheNameOfthePlace(
      clampLatitude(center.lat),
      clampLongitude(center.lng)
    );
  };

  const changeMapType = (type: google.maps.MapTypeId) => {
    if (map) {
      map.setMapTypeId(type);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showCurrentPosition, showError, {
        enableHighAccuracy: true,
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showCurrentPosition = (position: any) => {
    const newCenter = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    setCenter(newCenter);
  };

  const showError = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");

        break;

      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");

        break;

      case error.TIMEOUT:
        alert("The request to get user location timed out.");

        break;

      default:
        alert("An unknown error occurred.");

        break;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event: Event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setSearchQuery("");
      setIsOpen(false);
    }
  };
  function clampLatitude(lat: number): number {
    return Math.max(-90, Math.min(90, lat));
  }

  const clampLongitude = (lng: number): number => {
    lng = lng % 360;
    if (lng > 180) {
      lng -= 360;
    } else if (lng < -180) {
      lng += 360;
    }
    return lng;
  };
  const handleSelect = (item: any) => {
    setSearchQuery(item.description);
    setPlaceIdentifier(item.place_id);
    setIsOpen(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    const dataToSend = {
      title: marketLocation,
      lat: center.lat,
      long: center.lng,
      additional_info: additionalInfo,
    };
    handledataSubmition(dataToSend)
      .then((res) => {
        setPopupOpen(true);
        setDigitaladress(res.data.digital_address);

        setLoading(false);
        navigate("/popup", {
          state: { digitalAddress: res.data.digital_address },
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  return (
    <section
      id="global"
      className=" inset-0 z-50 flex h-96 items-center justify-center flex-wrap overflow-y-auto "
    >
      <div className="container px-4 mx-auto">
        <div className="relative bg-white shadow-3xl max-w-4xl mx-auto md:p-8 p-4   overflow-y-auto">
          <div
            className="bg-black/70 absolute top-0 bottom-0 left-0 right-0 z-50 flex justify-center items-center"
            style={{
              display: isLoading ? "flex" : "none",
            }}
          ></div>

          <div className="relative mb-8" ref={containerRef}>
            <div className="flex items-center overflow-hidden border w-full focus-within:border-indigo-500">
              <div className="pl-3.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="text-gray-400 w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                className="pl-2 pr-3.5 w-full bg-transparent outline-none py-4"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setIsOpen(true);
                }}
                onFocus={() => setIsOpen(true)}
                id="inputsInput7-1"
                type="text"
                placeholder="Search for streets, cities, districts..."
              />
            </div>

            {isOpen && searchResults.length > 0 && (
              <div className="absolute mt-1 w-full p-2 bg-white shadow-lg rounded-b max-h-36 overflow-y-auto z-50">
                <ul>
                  {searchResults.map((item: any) => (
                    <div
                      key={item.placeIdentifier}
                      className="p-2 hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleSelect(item)}
                    >
                      <li>{item.description}</li>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="relative mb-8 overflow-hidden h-600">
            <div className="absolute right-1/2 top-1/2 transform translate-x-1/2 -translate-y-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="black"
                viewBox="0 0 24 24"
                className="w-12 h-12"
              >
                <g
                  stroke-linecap="square"
                  stroke-linejoin="round"
                  stroke-width="0.8"
                >
                  <g>
                    <g>
                      <path
                        stroke-width="0"
                        d="M2.08,7.02V2.67c0-.33.27-.6.6-.6h4.35v1.2h-3.75v3.75h-1.2Z"
                      ></path>
                      <path
                        fill="#ffffff"
                        d="M6.83,2.27v.8h-3.75v3.75h-.8V2.67c0-.22.18-.4.4-.4h4.15M7.23,1.87H2.68c-.44,0-.8.36-.8.8v4.55h1.6v-3.75h3.75v-1.6h0Z"
                      ></path>
                    </g>
                    <g>
                      <path
                        stroke-width="0"
                        d="M18.58,7.02v-3.75h-3.75v-1.2h4.35c.33,0,.6.27.6.6v4.35h-1.2Z"
                      ></path>
                      <path
                        fill="#ffffff"
                        d="M19.18,2.27c.22,0,.4.18.4.4v4.15h-.8v-3.75h-3.75v-.8h4.15M19.18,1.87h-4.55v1.6h3.75v3.75h1.6V2.67c0-.44-.36-.8-.8-.8h0Z"
                      ></path>
                    </g>
                    <g>
                      <path
                        stroke-width="0"
                        d="M14.83,19.77v-1.2h3.75v-3.75h1.2v4.35c0,.33-.27.6-.6.6h-4.35Z"
                      ></path>
                      <path
                        fill="#ffffff"
                        d="M19.58,15.02v4.15c0,.22-.18.4-.4.4h-4.15v-.8h3.75v-3.75h.8M19.98,14.62h-1.6v3.75h-3.75v1.6h4.55c.44,0,.8-.36.8-.8v-4.55h0Z"
                      ></path>
                    </g>
                    <g>
                      <path
                        stroke-width="0"
                        d="M2.68,19.77c-.33,0-.6-.27-.6-.6v-4.35h1.2v3.75h3.75v1.2H2.68Z"
                      ></path>
                      <path
                        fill="#ffffff"
                        d="M3.08,15.02v3.75h3.75v.8H2.68c-.22,0-.4-.18-.4-.4v-4.15h.8M3.48,14.62h-1.6v4.55c0,.44.36.8.8.8h4.55v-1.6h-3.75v-3.75h0Z"
                      ></path>
                    </g>
                    <circle
                      fill="#db0000"
                      stroke="#ffffff"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                      stroke-width="0.8"
                      cx="10.93"
                      cy="10.92"
                      r="1.95"
                    ></circle>
                    <g>
                      <rect
                        stroke-width="0"
                        x="10.33"
                        y=".2"
                        width="1.2"
                        height="4.95"
                      ></rect>
                      <path
                        fill="#ffffff"
                        d="M11.33.4v4.55h-.8V.4h.8M11.73,0h-1.6v5.35h1.6V0h0Z"
                      ></path>
                    </g>
                    <g>
                      <rect
                        stroke-width="0"
                        x="10.33"
                        y="16.7"
                        width="1.2"
                        height="4.95"
                      ></rect>
                      <path
                        fill="#ffffff"
                        d="M11.33,16.9v4.55h-.8v-4.55h.8M11.73,16.5h-1.6v5.35h1.6v-5.35h0Z"
                      ></path>
                    </g>
                    <g>
                      <rect
                        stroke-width="0"
                        x="16.7"
                        y="10.32"
                        width="4.95"
                        height="1.2"
                      ></rect>
                      <path
                        fill="#ffffff"
                        d="M21.45,10.52v.8h-4.55v-.8h4.55M21.85,10.12h-5.35v1.6h5.35v-1.6h0Z"
                      ></path>
                    </g>
                    <g>
                      <rect
                        stroke-width="0"
                        x=".2"
                        y="10.33"
                        width="4.95"
                        height="1.2"
                      ></rect>
                      <path
                        fill="#ffffff"
                        d="M4.95,10.53v.8H.4v-.8h4.55M5.35,10.13H0v1.6h5.35v-1.6h0Z"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d1010.4220177782644!2d32.6164463!3d0.362759!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwMjEnNDUuOSJOIDMywrAzNycwMS41IkU!5e1!3m2!1sen!2sug!4v1709029010188!5m2!1sen!2sug" width="100%" height="100%" style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}

            <div style={{ height: "480px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                }}
                key={key}
                center={center}
                defaultZoom={11}
                yesIWantToUseGoogleMapApiInternals
                onChange={handleMapChange}
                onGoogleApiLoaded={handleApiLoaded}
                draggable={isMapLocked}
                options={{
                  mapTypeId: mapType,
                }}
              >
                <AnyReactComponent
                  lat={marker.lat}
                  lng={marker.lng}
                  text={marker.text}
                />
              </GoogleMapReact>

              <div className=" top-0 left-0 w-full h-full flex justify-center items-end pb-4">
                <div className="absolute bottom-0 left-0 w-full flex justify-around p-2">
                  <div className="flex items-center absolute bottom-0 transform right-1/2 translate-x-1/2 flex-wrap pb-4">
                    <div className="w-full px-2">
                      <p className="w-full space-x-2 text-center bg-white mb-4">
                        <span>Coordinates :</span>
                        <span className="font-bold">{center.lat}°N</span>
                        <span className="font-bold">{center.lng}°E</span>
                      </p>
                    </div>

                    <div
                      onClick={() => setIsMapLocked(!isMapLocked)}
                      className="w-1/2 p-2"
                    >
                      <a className="border flex gap-2 items-center bg-white transform p-3 font-medium hover:text-indigo-500 text-sm justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          stroke-width="1.5"
                          stroke="currentColor"
                          viewBox="0 0 24.83 24.83"
                          className="w-4 h-4"
                          data-name="Layer 2"
                        >
                          <g>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12.42.75l-3.18,3.18M12.42.75l3.18,3.18M12.42.75v7.42M.75,12.42l3.18-3.18M.75,12.42l3.18,3.18M.75,12.42h7.42M24.08,12.42l-3.18-3.18M24.08,12.42l-3.18,3.18M24.08,12.42h-7.42M12.42,24.08l-3.18-3.18M12.42,24.08l3.18-3.18M12.42,24.08v-7.42"
                            ></path>
                          </g>
                        </svg>
                        <span className="cursor">Move map manually</span>
                      </a>
                    </div>

                    <div onClick={getLocation} className="w-1/2 p-2">
                      <a className="border flex gap-2 items-center bg-white transform p-3 font-medium hover:text-indigo-500 text-sm justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4"
                        >
                          <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
                        </svg>
                        <span className="cursor">Get my location</span>
                      </a>
                    </div>
                  </div>

                  <button
                    className="absolute bottom-4 left-4 z-10 p-2 bg-white border border-gray-200 rounded shadow-lg"
                    onClick={toggleMapType}
                  >
                    {mapType === "roadmap" ? (
                      <img
                        className="w-8 h-8"
                        src={SatelliteIcon}
                        alt="Satellite"
                      />
                    ) : (
                      <img
                        className="w-8 h-8"
                        src={DefaultIcon}
                        alt="Default"
                      />
                    )}
                  </button>
                </div>
              </div>
              <div className="w-1/2 p-2">
                <a className=" border flex gap-2 items-center bg-white transform p-3 font-medium hover:text-indigo-500 text-sm justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4"
                  >
                    <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
                  </svg>

                  <span>Get my location</span>
                </a>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-wrap items-center -m-2 mb-4">
            <div className="w-full p-2 hidden">
              <p className="space-x-2 text-lg">
                <span>Business name :</span>

                <span className="font-bold">
                  Africa Treasures and Tours Limited
                </span>
              </p>
            </div>

            <div className="w-full p-2">
              <div className="w-full flex items-center overflow-hidden border focus-within:border-indigo-500">
                <div className="pl-3.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="text-gray-400 w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    ></path>
                  </svg>
                </div>

                <input
                  className="w-full bg-transparent outline-none py-4 px-3"
                  id="inputsInput7-1"
                  type="text"
                  value={marketLocation}
                  placeholder="Address"
                />
              </div>
            </div>

            <div className="w-full p-2">
              <div className="w-full flex items-center overflow-hidden border focus-within:border-indigo-500">
                <div className="pl-3.5 hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="text-gray-400 w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    ></path>
                  </svg>
                </div>

                <input
                  className="w-full bg-transparent outline-none py-4 px-3"
                  id="inputsInput7-1"
                  type="text"
                  placeholder="Additional information"
                  onChange={(e) => {
                    setAdditionalInfo(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div
            onClick={!isLoading ? handleSubmit : undefined}
            className="w-full flex flex-wrap overflow-hidden mb-2 justify-end"
          >
            <a className="px-6 py-4 font-medium transition duration-300 bg-black hover:bg-gray-800 w-full text-center text-white bg-indigo-500 hover:bg-indigo-600">
              Confirm address
            </a>
          </div>

          <p className="text-neutral-500 mb-2 text-xs">
            <span className="">
              This digital address will be automatically created and linked to
              your personal account for effortless future access.{" "}
            </span>

            <a className="hover:underline text-blue-500 hover:text-blue-600">
              Learn more
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default AddDigitalAddressStep;

interface MapClickEvent {
  x: number;

  y: number;

  lat: number;

  lng: number;

  event: MouseEvent;
}

interface MapsApi {
  map: google.maps.Map;

  maps: typeof google.maps;
}

interface MapChangeEvent {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  bounds: {
    nw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
    se: { lat: number; lng: number };
  };
  marginBounds: {
    nw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
    se: { lat: number; lng: number };
  };
  size: {
    width: number;
    height: number;
  };
}

interface GeocodeOptions {
  key: string;
  language: string;
  region: string;
  outputFormat: string; // 'json' or 'xml' or whatever the API supports
}

enum OutputFormat {
  JSON = "json",
  XML = "xml",
}
