import React, { useState } from "react";
import Logo from "../assets/images/ePosta-Logo.svg";
import { checkDigitalAdress } from "../services/digital-address/api";

import Modal from "react-modal";
import { Outlet, useNavigate } from "react-router-dom";

export default function HomePage() {
  const [digital, setDigital] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<"valid" | "not_valid" | "pending">(
    "pending"
  );
  const navigate = useNavigate();
  const [isValidFormat, setIsValidFormat] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.toUpperCase(); // Convert all input to uppercase for uniform handling

    // Use regex to enforce the format and handle the input dynamically
    if (/^[A-Z]{0,2}$/.test(value)) {
      // Allows only two letters
      setDigital(value);
    } else if (/^[A-Z]{2}-$/.test(value)) {
      // Allows two letters followed by a hyphen
      setDigital(value);
    } else if (/^[A-Z]{2}-[\d]{0,4}$/.test(value)) {
      // Allows numbers after the hyphen, up to four digits
      setDigital(value);
    } else if (/^[A-Z]{2}-[\d]{4}-[\d]{0,4}$/.test(value)) {
      // Allows a hyphen after four digits and up to four more digits
      setDigital(value);
    } else if (/^[A-Z]{2}-[\d]{4}-[\d]{4}$/.test(value)) {
      // Completes the format with a total of eight digits, formatted as xxxx-xxxx
      setDigital(value);
    }
    // Do not update state if the input does not match the expected patterns
  };

  const handleSubmission = () => {
    checkDigitalAdress(digital)
      .then((res) => {
        console.log(res);
        setIsValid("valid");
      })
      .catch((err) => {
        console.error(err);
        navigate("/phonecheck");

        setIsValid("not_valid");
      });
  };
  return (
    <section className="py-8 bg-white h-screen">
      <div className="container px-4 mx-auto">
        <div className="p-2 lg:p-4 max-w-xl mx-auto">
          <img src={Logo} alt="" className="mx-auto h-16 mb-8" />
          <p className=" text-lg mb-2">Enter digital address</p>
          <div className="w-full flex items-center overflow-hidden border focus-within:border-indigo-500 mb-2">
            <input
              className="w-full bg-transparent outline-none py-4 px-3 font-bold align-text-bottom"
              id="inputsInput7-1"
              type="text"
              onChange={handleChange}
              placeholder="UG-xxxx-xxxx"
              value={digital}
              required
            />
          </div>

          <Modal
            isOpen={isOpen}
            onRequestClose={() => {
              setIsOpen(false);
            }}
            className="fixed inset-0 flex items-center justify-center p-5"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
          >
            <div className="modal-content bg-white p-4 rounded-lg shadow-lg">
              <div className="flex justify-between items-center border-b pb-2 lg:pb-4">
                <h3 className="font-heading font-semibold lg:text-xl">
                  Add digital address
                </h3>
                <button
                  className="p-1"
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    setIsOpen(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                  <span className="sr-only">Close</span>
                </button>
              </div>
              <div className="max-h-screen overflow-y-auto">
                <Outlet context={[isOpen, setIsOpen]} />
              </div>
            </div>
          </Modal>
          {isValid === "not_valid" && (
            <p className="text-sm lg:text-base text-red-500 mb-4">
              this digital address does not exist. Please provide a valid
              address or create a new one.
            </p>
          )}
          {isValid === "valid" && (
            <p className="text-sm lg:text-base text-green-500 mb-4">
              this digital address is available and ready to be linked with your
              box.
            </p>
          )}

          <a
            onClick={handleSubmission}
            className="cursor mb-4 w-full px-6 py-4 font-medium transition duration-300 w-full md:w-auto text-center text-white bg-indigo-500 hover:bg-indigo-600 block"
          >
            Verify Digital Address
          </a>
          <p className="text-center ">
            <span className="">Don't have a digital address?</span>
            <a
              onClick={() => {
                setIsOpen(true);
                setIsValid("pending");
                navigate("/phonecheck");
              }}
              className=" cursor hover:underline text-blue-500 hover:text-blue-600"
            >
              Create a new one
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
