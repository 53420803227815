import React, { useEffect, useRef, useState } from "react";

import Popup from "../components/api-completed";
import AddDigitalAddressStep from "./add-digitaladress";
import PhoneFieldStep from "../components/PhoneFieldStep";
import HomePage from "./HomePage";
import VerifyCodeStep from "../components/VerifyCodeStep";

import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}>
        <Route index path="/phonecheck" element={<PhoneFieldStep />} />
        <Route path="/verification" element={<VerifyCodeStep />} />
        <Route
          path="/createDigitalAdress"
          element={<AddDigitalAddressStep />}
        />
        <Route path="/popup" element={<Popup />} />
      </Route>
    </Routes>
  );
};

export default App;
